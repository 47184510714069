import { Injectable } from '@angular/core';
import { Observable, map, of, switchMap, tap, timer } from 'rxjs';

const ERROR: string = 'MY-ERROR';

@Injectable({
  providedIn: 'root',
})
export class PersistenceService {
  constructor() {}

  //eslint-disable-next-line
  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('localStorage.setItem', e);
    }
  }

  get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key) ?? 'null');
    } catch (e) {
      console.error('localStorage.getItem', e);
      return null;
    }
  }

  getLocalStorage(): string {
    try {
      return JSON.stringify(localStorage);
    } catch (e) {
      //console.error(e);
      return '';
    }
  }

  setCachedLocalStorage(cachedLocalStorage: string): void {
    try {
      Object.entries(JSON.parse(cachedLocalStorage)).forEach(([key, value]) => {
        localStorage.setItem(key, value as string);
      });
    } catch (e) {}
  }

  hasKey(key: string): boolean {
    return null !== localStorage.getItem(key);
  }

  remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      //console.error(e);
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch (e) {
      //console.error(e);
    }
  }

  clearByTestId(testId: string): void {
    try {
      const removeKeys = ['test_' + testId, 'finishSession_' + testId, 'completeContent_' + testId, 'redirectUrl_' + testId];

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        //console.log(i, key);
        if (key != null && localStorage.getItem(key) !== 'undefined') {
          const value = JSON.parse((localStorage.getItem(key) as String)?.toString());

          if (value.testId === testId) {
            removeKeys.push(key);
          }
        }
      }

      removeKeys.forEach((key) => {
        localStorage.removeItem(key);
      });
    } catch (e) {
      //console.error(e);
    }
  }

  setAsync(key: string, data: any): Observable<any> {
    return of(this.setWithCustomError(key, data)).pipe(
      switchMap((v) => {
        if (v === ERROR) {
          return timer(1000).pipe(tap(() => this.set(key, data)));
        }
        return of('');
      }),
    );
  }

  getAsync(key: string): Observable<any> {
    return of(this.getWithCustomError(key)).pipe(
      switchMap((v) => {
        if (v === ERROR) {
          return timer(1000).pipe(map(() => this.get(key)));
        }
        return of(v);
      }),
    );
  }

  private setWithCustomError(key: string, data: any): void | string {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('localStorage.setItem', e);
      return ERROR;
    }
  }

  private getWithCustomError(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key) ?? 'null');
    } catch (e) {
      console.error('localStorage.getItem', e);
      return ERROR;
    }
  }
}
